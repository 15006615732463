var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ActivityDetail",style:({ backgroundColor: _vm.activityInfo.activityBackColor })},[(_vm.ChannelModule.channel === 'plat_h5')?_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":_vm.activityInfo.activityName,"left-arrow":""},on:{"click-left":_vm.onClickLeft}}):_vm._e(),_c('van-row',{staticClass:"banner",style:({
      'padding-top': _vm.ChannelModule.channel === 'plat_h5' ? "0.88rem" : '0',
    })},[(_vm.activityInfo.headImageUrl)?_c('van-image',{staticClass:"head-image",attrs:{"fit":_vm.imageFit,"src":_vm.activityInfo.headImageUrl}}):_vm._e(),_c('van-image',{staticClass:"rule",style:({
        top: _vm.ChannelModule.channel === 'plat_h5' ? '0.88rem' : '0rem',
      }),attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/CollectPrint/act-desc.png')},on:{"click":function($event){_vm.showActDesc = true}}})],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"safe",style:({ backgroundColor: _vm.activityInfo.activityBackColor })},[_c('van-row',{staticClass:"activity-progress"},[_c('van-col',{staticClass:"progress-top"},[_c('van-row',{staticClass:"progress"},[_c('span',{staticClass:"title"},[_vm._v("任务进度")]),_c('div',{staticClass:"btn"},[_c('span',{staticStyle:{"font-size":"0.6rem","color":"#ffffff","font-weight":"500"}},[_vm._v(_vm._s(_vm.activityInfo.finishTaskNumber)+" ")]),_vm._v(" /"+_vm._s(_vm.activityInfo.taskNumber)+" ")])]),_c('van-row',{staticClass:"print"},[_c('span',{staticClass:"title"},[_vm._v("集印花数")]),_c('div',{staticClass:"btn"},[_c('span',{staticStyle:{"font-size":"0.6rem","color":"#ff3e4e","font-weight":"500"}},[_vm._v(_vm._s(_vm.activityInfo.finishPrintingNumber))]),_vm._v("/"+_vm._s(_vm.activityInfo.printingNumber)+" ")])])],1),_c('van-col',{staticClass:"exchange",attrs:{"disabled":_vm.activityInfo.exchangeOverdue},on:{"click":function($event){return _vm.$router.push(
              ("/print-exchange?activityId=" + (_vm.activityInfo.activityId))
            )}}},[_c('van-icon',{staticClass:"giftIcon",attrs:{"name":"point-gift-o"}}),_c('a',[_vm._v("兑换好礼")])],1),_c('van-col',{staticClass:"end-times"},[_c('van-image',{staticStyle:{"height":"0.27rem","width":"0.27rem"},attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/CollectPrint/clock.png')}}),_vm._v(" 印花兑换截止时间："+_vm._s(_vm.activityInfo.exchangeEndTime)+" ")],1)],1),(_vm.activityInfo.drawStatus && _vm.activityInfo.drawStatus !== '0')?_c('van-row',{staticClass:"second-banner"},[_c('div',{staticClass:"draw-wrap",class:{ disable: _vm.activityInfo.drawStatus === '2' },attrs:{"disabled":_vm.activityInfo.drawStatus === '2'},on:{"click":_vm.gotoDrawAct}},[(
              _vm.activityInfo.infoResponse &&
              _vm.activityInfo.infoResponse.purposePictureUrl
            )?_c('van-image',{attrs:{"width":"100%","height":"100%","fit":_vm.imageFit,"src":_vm.activityInfo.infoResponse.purposePictureUrl}}):_vm._e()],1)]):_vm._e(),_c('van-row',{staticClass:"task-container"},[_c('van-col',{staticClass:"bar"}),_vm._l((_vm.taskList),function(item,index){return _c('van-col',{key:index,staticClass:"task-item"},[_c('van-row',{staticClass:"item-left"},[_c('van-image',{staticClass:"taskImage",attrs:{"fit":_vm.imageFit,"src":_vm.taskImageUrl(item.taskType)}})],1),_c('van-row',{staticClass:"item-right"},[_c('van-col',{staticClass:"item-desc"},[_c('span',{staticClass:"task-name"},[_vm._v(_vm._s(item.taskName.slice(0, 50)))]),_c('span',{staticClass:"task-desc"},[_vm._v(_vm._s(item.taskRuleDescription))])]),_c('van-col',{staticClass:"btns"},[(
                  item.taskStatus === '0' && !_vm.activityInfo.activityOverdue
                )?_c('p',[_c('span',{staticStyle:{"display":"block","font-weight":"500","font-size":"0.32rem","color":"#999999"}},[_vm._v(_vm._s(_vm.timestampFormat(Number(item.beginTime), "MM月DD日")))]),_c('span',{staticStyle:{"display":"block","font-weight":"400","font-size":"0.28rem","color":"#999999","text-align":"center"}},[_vm._v("即将开始")])]):_c('van-button',{staticClass:"btn",class:{
                  'not-clickable':
                    _vm.activityInfo.activityOverdue ||
                    !_vm.activityInfo.activityStart,
                },attrs:{"disabled":_vm.activityInfo.activityOverdue ||
                  !_vm.activityInfo.activityStart ||
                  item.taskStatus === '2' ||
                  _vm.activityInfo.exchangeOverdue},on:{"click":function($event){return _vm.todoTask(
                    item,
                    item.taskId,
                    item.taskType,
                    item.insertOrUpdatetRpcRequest,
                    item.rangeType,
                    item.ruleId,
                    item.isOnline,
                    item.taskFinishFlag
                  )}}},[_vm._v(" "+_vm._s(_vm.taskStatusName( item.taskStatus, item.taskType, item.taskFinishFlag, item.isOnline ).name)+" ")])],1)],1)],1)})],2)],1)]),(_vm.showFinish)?_c('div',{staticClass:"finish-dialog-wrap"},[_c('div',{staticClass:"finish-dialog",on:{"click":function($event){_vm.showFinish = false}}},[_c('span',{staticClass:"sign-tip"},[_vm._v("恭喜您，打卡成功！")]),_c('span',{staticClass:"flower-tip"},[_vm._v("获得印花")])])]):_vm._e(),_c('van-dialog',{staticClass:"nodialog",attrs:{"confirm-button-text":"我知道了"},on:{"confirm":function($event){_vm.showActDesc = false}},model:{value:(_vm.showActDesc),callback:function ($$v) {_vm.showActDesc=$$v},expression:"showActDesc"}},[_c('div',{staticClass:"r-title"},[_vm._v("活动规则")]),_c('div',{staticClass:"f-rules"},[_c('div',{staticClass:"r-main",domProps:{"innerHTML":_vm._s(_vm.activityInfo.activityDescription)}})])]),_c('van-dialog',{staticClass:"download-dialog",attrs:{"title":"下载保利票务APP","show-cancel-button":"","confirm-button-text":"下载APP","close-on-click-overlay":true},on:{"confirm":_vm.downloadApp},model:{value:(_vm.showDownload),callback:function ($$v) {_vm.showDownload=$$v},expression:"showDownload"}},[_vm._v(" 使用保利票务APP，享受更多优惠！ ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }