var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExchangeGood"},[(_vm.ChannelModule.channel === 'plat_h5')?_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":"兑换好礼","left-arrow":""},on:{"click-left":_vm.onClickLeft}}):_vm._e(),_c('div',{staticClass:"container",style:(_vm.ChannelModule.channel === 'plat_h5'
        ? "padding-top:1.08rem"
        : "padding-top:0")},[_c('div',{staticClass:"header"},[_c('van-row',{staticClass:"h-top"},[_c('van-col',{staticClass:"print-all"},[_c('p',{staticClass:"circular"},[_c('a',{style:({
                'font-size':
                  _vm.activityInfo.finishPrintingNumber.toString().length > 2
                    ? '0.34rem'
                    : '0.42rem',
              })},[_vm._v(_vm._s(_vm.activityInfo.finishPrintingNumber))]),_c('span',{staticStyle:{"font-size":"0.3rem"}},[_vm._v("/"+_vm._s(_vm.activityInfo.printingNumber))])]),_c('span',{staticClass:"text"},[_vm._v("集印花数")])]),_c('van-col',{staticClass:"print-exchange"},[_c('p',{staticClass:"circular"},[_vm._v(_vm._s(_vm.activityInfo.usableNum))]),_c('span',{staticClass:"text"},[_vm._v("可兑换印花")])]),_c('van-col',{staticClass:"print-used"},[_c('p',{staticClass:"circular"},[_vm._v(_vm._s(_vm.activityInfo.expendNum))]),_c('span',{staticClass:"text"},[_vm._v("已消耗印花")])])],1),_c('van-row',{staticClass:"h-bottom"},[_c('van-button',{staticClass:"btn",attrs:{"disabled":_vm.activityInfo.activityOverdue},on:{"click":_vm.gotoTask}},[(_vm.activityInfo.activityOverdue)?_c('a',[_vm._v("活动结束")]):_c('a',[_vm._v(" "+_vm._s(_vm.activityInfo.taskAllFinishFlag ? "已完成" : "去完成任务")+" ")])])],1)],1),_c('goodList',{attrs:{"record-list":_vm.RecordList,"activity-id":_vm.activityId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }