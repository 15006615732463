


















































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  NavBar,
  Row,
  Col,
  Icon,
  Image,
  List,
  Cell,
  Button,
  Field,
  CellGroup,
} from "vant";
import goodList from "./goodList.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Field.name]: Field,
    goodList,
  },
})
export default class printOrderDetail extends Mixins(Mixin) {
  public orderDetail = {
    status: "1",
    image: "",
    title: "澳洲进口fiveram洗衣液天然萃取温和不伤皮肤保护娇嫩双手",
    num: 2,
    printCount: 20,
    totalPrint: 20,
    exchangeCode: "20201234567890",
    orderCode: "12345678910",
    orderTime: "2018.09.09 12:00",
    receiveTime: "2018.09.09 12:00",
    notice: "凭兑换码到保利剧院客户服务台领取凭兑换码到保利剧院客户服务台领取",
  };
  public message = "";
  created(): void {
    // this.getOrderDetail();
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  getOrderDetail(): void {
    // 接口位置
  }
  exchangeConfirm(): void {
    // this.$toast("兑换");
  }
}
